import React from 'react';

import * as Styled from './styles';
import { accessIconSwitcher } from './consts';
import { AccessListProps } from './types';

const AccessList: React.FC<AccessListProps> = ({ items }) => (
  <Styled.AccessList>
    {items.map(({ key, title, transports }) => (
      <Styled.AccessListItem {...{ key }}>
        <Styled.AccessListTitle variant="textL">{title}</Styled.AccessListTitle>
        <Styled.AccessListTransport>
          {transports.map(({ key: transportKey, icon, desc }) => (
            <Styled.AccessListTransportItem key={transportKey}>
              <Styled.AccessListTransportIcon>
                {accessIconSwitcher(icon)}
              </Styled.AccessListTransportIcon>
              <Styled.AccessListTransportDesc variant="textL">
                {desc}
              </Styled.AccessListTransportDesc>
            </Styled.AccessListTransportItem>
          ))}
        </Styled.AccessListTransport>
      </Styled.AccessListItem>
    ))}
  </Styled.AccessList>
);

export default AccessList;
