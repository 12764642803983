import { graphql } from 'gatsby';
import React from 'react';
import { getEnumValueByStringValue } from '../../../../utils/object';
import { AccessIconMode } from '../../../molecules/AccessList/enums';

import { BLOG_INNER_MAX_WIDTH } from '../../const';
import TransportSchedule from '../../TransportSchedule';
import { TransportScheduleSectionProps } from './types';

const TransportScheduleSection: React.VFC<TransportScheduleSectionProps> = ({
  items,
  innerMode,
}) => (
  <TransportSchedule
    data={{
      innerMode: innerMode ?? BLOG_INNER_MAX_WIDTH,
      items: (items ?? []).map((transportGroup) => {
        const { _key: key, title, subtitle, items: transportDayItems } = transportGroup ?? {};

        return {
          key: key ?? '',
          title: title ?? '',
          subtitle: subtitle ?? '',
          items: (transportDayItems ?? []).map((transportDayItem) => {
            const { _key: dayKey, title: dayTitle, items: transportItems } = transportDayItem ?? {};

            return {
              key: dayKey ?? '',
              title: dayTitle ?? '',
              transports: (transportItems ?? []).map((transportItem) => {
                const { _key: transportKey, desc, iconMode } = transportItem ?? {};

                return {
                  key: transportKey ?? '',
                  desc: desc ?? '',
                  icon: getEnumValueByStringValue(AccessIconMode, iconMode) || AccessIconMode.Car,
                };
              }),
            };
          }),
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment TransportScheduleSectionFragment on SanityTransportScheduleSection {
    _key
    _type
    items: transportGroup {
      _key
      title
      subtitle
      items: transportDayItem {
        _key
        title
        items: transportItem {
          _key
          iconMode
          desc
        }
      }
    }
    innerMode
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default TransportScheduleSection;
