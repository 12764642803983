import React from 'react';

import { AccessIconMode } from './enums';
import { IconDriving, IconAirplaneSquare, IconBus } from '../../../assets/svg';

export const accessIconSwitcher = (param: AccessIconMode | null) => {
  switch (param) {
    case AccessIconMode.Car:
      return <IconDriving />;
    case AccessIconMode.Bus:
      return <IconBus />;
    case AccessIconMode.Plane:
      return <IconAirplaneSquare />;
    default:
      return null;
  }
};
