import React from 'react';

import * as Styled from '../TextSection/styles';

import TextIcons from '../../molecules/TextIcons';
import { TransportScheduleProps } from './types';

const TransportSchedule: React.FC<TransportScheduleProps> = ({ data: { innerMode, items } }) => (
  <Styled.CustomInner {...{ innerMode }}>
    <TextIcons {...{ items }} />
  </Styled.CustomInner>
);

export default TransportSchedule;
