import styled from 'styled-components';

import media from '../../../common/MediaQueries';
import { FlexBox } from '../../styles/Grid/styles';
import Typography from '../../styles/Typography';

export const TextIcons = styled(FlexBox)`
  flex-direction: column;
  @media ${media.phone} {
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    margin-top: -1.5rem;
  }
`;

export const TextIconsCol = styled.div`
  @media ${media.maxPhone} {
    &:not(:first-child) {
      margin-top: 2.5rem;
    }
  }
  @media ${media.phone} {
    width: 50%;
    padding: 1.5rem 1.5rem 0;
    &:first-child:last-child {
      width: 100%;
    }
  }
`;

export const TextIconsTitle = styled(Typography)``;

export const TextIconsSubtitle = styled(Typography)`
  margin-top: 1.5rem;
  color: ${({ theme }) => theme.colors.secondary.hover};
`;

export const TextIconsItems = styled.div`
  margin-top: 1.5rem;
`;
