import styled from 'styled-components';

import Typography from '../../styles/Typography';

export const AccessList = styled.ul``;

export const AccessListItem = styled.li`
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

export const AccessListTitle = styled(Typography)``;

export const AccessListTransport = styled.ul`
  margin-top: 1.5rem;
`;

export const AccessListTransportItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const AccessListTransportIcon = styled.span`
  margin-right: 1.125rem;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.secondary.hover};
`;

export const AccessListTransportDesc = styled(Typography)`
  color: ${({ theme }) => theme.colors.additional.accentGray2};
`;
